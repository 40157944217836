.main_me {
	width: 100%;
	margin: 0;
	
	.main-wrapper {
		max-width: 1280px;
		height: 100%;
		margin: 0 auto;
	}
	.main-container {
		height: 100%;
		padding-bottom: $header-container-margin-horizontal;
		
		@media (max-width: $screen-xs) {
			padding-bottom: ($screen-xs * ($header-container-margin-horizontal / 100%));
		}
	}
	.main-intro-message-wrapper {
		position: relative;
		height: 100%;
	}
}

.me-section:first-child {
	.main-wrapper {
		padding-top: 96px;
	}
}

.me-section_fullscreen {
	width: 100%;
	height: 100vh;
	min-height: 480px;
	margin-top: -96px;
}
.me-section_intro {
	background: $color-pure-blue;
}
.me-intro-message {
	position: absolute;
	left: 0;
	bottom: 0;
	font-size: 80px;
	font-weight: 600;
	line-height: calc(100/80);
	color: white;
}
.me-intro-message-hello {
	color: rgba(white, 0.5);
}