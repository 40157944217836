body {
	margin: 0;
	padding: $header-height 0 0;
	
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	
	.wrapper {
		width: 100%;
		height: inherit;
		max-width: 1280px;
		margin: 0 auto;
	}
	.container {
		overflow: hidden;
		
		height: 100%;
		margin: 0 $header-container-margin-horizontal;
		
		@media (max-width: $screen-xs) {
			margin: 0 ($screen-xs * ($header-container-margin-horizontal / 100%));
		}
	}
}

.main {
	.container {
		padding-top: $header-container-margin-horizontal;
		padding-bottom: $header-container-margin-horizontal;
		@media (max-width: $screen-xs) {
			padding-top: ($screen-xs * ($header-container-margin-horizontal / 100%));
			padding-bottom: ($screen-xs * ($header-container-margin-horizontal / 100%));
		}
	}
}