$font-family-sans:			-apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Apple SD Gothic Neo',
							'Roboto', 'Noto Sans KR', 'Noto Sans CJK KR',
							'Helvetica', 'Arial', 'Malgun Gothic', 'Dotum', sans-serif;
$font-family-sans-display:	'.SF NS Display', $font-family-sans;
$font-family-sans-text:		'.SF NS Text', $font-family-sans;
$font-family-sans-avenir:	'Avenir Next', 'Avenir', 'Arita Dotum', $font-family-sans-display;
$font-family-serif:			'KoPub Batang', 'Nanum Myeongjo', serif;
$font-family-mono:			'SF Mono', Menlo, Monaco, Consolas, monospace;
$font-family-base:			$font-family-sans-display;

$font-size-base:	16px;
$font-size-large:	$font-size-base * 1.25;
$font-size-small:	$font-size-base * 0.75;

$font-size-h1:		$font-size-base * 2;	// 32px
$font-size-h2:		$font-size-base * 1.75;	// 28px
$font-size-h3:		$font-size-base * 1.5;	// 24px
$font-size-h4:		$font-size-base * 1.25;	// 20px
$font-size-h5:		$font-size-base * 1;	// 16px
$font-size-h6:		$font-size-base * 0.75;	// 12px

$line-height-base:	1.5;

$headings-font-weight:	500;
$headings-line-height:	1.25;


h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

code { font-family: $font-family-mono; }