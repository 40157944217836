.body_portfolio {
	background: $color-pure;
}

.main_portfolio {
	> .wrapper {
		max-width: $screen-md;
	}
}

.portfolio-list {
	margin: 0;
	padding: 0;
	
	list-style-type: none;
	
	.portfolio-list-item-wrapper {
		margin: 0;
		padding: 0;
		
		overflow: hidden;
		
		@media (max-width: $screen-sm) {
			@include card_portfolio-tile('.portfolio-list-item', 10px, 1);
		}
		@media (min-width: $screen-sm) and (max-width: $screen-md)  {
			@include card_portfolio-tile('.portfolio-list-item', 12px, 2);
		}
		@media (min-width: $screen-md) {
			@include card_portfolio-tile('.portfolio-list-item', 16px, 3);
		}
	}
	
	.portfolio-list-item {
		display: block;
		float: left;
		
		> .card_portfolio {
			display: block;
			
			margin: 0;
			padding: 0;
			overflow: hidden;
			
			border: 1px solid $color-milk-gray-lightest;
			background: white;
			
			.card-cover {
				@include aspect-ratio('.card-cover-container', 16, 10);
				.card-cover-img {
					width: 100%;
					height: 100%;
					
					object-fit: cover;
				}
			}
			.card-info {
				padding: 12px 16px 16px;
				
				.card-info-item {
					display: block;
					
					margin: 0;
					padding: 0;
					
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					
					font-size: 16px;
					font-weight: 400;
					line-height: 1.25;
					text-align: left;
					
					color: $color-milk-black;
				}
				.card-info-item_title {
					font-size: 24px;
				}
				.card-info-item_date {
					margin-top: 2px;
					
					text-transform: uppercase;
					
					color: $color-milk-gray;
				}
			}
		}
	}
}