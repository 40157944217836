.body_blog {
	background: $color-milk;
}

.main_blog {
	> .wrapper {
		max-width: $screen-xs;
	}
}

.blog-list {
	margin: 0;
	padding: 0;
	
	list-style-type: none;
	
	@media (max-width: $screen-xs) {
		> .container {
			margin-left: 10px;
			margin-right: 10px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	
	.blog-list-item {
		display: block;
		
		> .card_blog {
			display: block;
			
			margin: 0;
			padding: 25px;
			overflow: hidden;
			
			border: 1px solid $color-milk-gray-lightest;
			background: white;
			
			.card-info {
				.card-info-item {
					display: block;
					
					margin: 0;
					padding: 0;
					
					font-size: 16px;
					font-weight: 400;
					line-height: 1.25;
					text-align: left;
					
					color: $color-milk-black;
				}
				.card-info-item_date {
					margin-bottom: 10px;
					
					text-transform: uppercase;
					
					color: $color-milk-gray-light;
				}
				.card-info-item_title {
					font-size: 32px;
					font-weight: 500;
				}
				.card-info-item_summary {
					margin-top: 10px;
					
					line-height: 2;
					
					color: $color-milk-gray;
				}
			}
		}
		
		&:not(:first-child) {
			> .card_blog {
				border-top: none;
			}
		}
	}
}