// GLOBAL
.post {
	overflow: hidden;
	
	color: $color-milk-black;
	
	.post-header {
		.post-header-info-row,
		.post-header-info-item {
			font-size: 20px;
		}
		.post-header-info-row {
			overflow: hidden;
		}
		.post-header-info-item {
			display: block;
			
			margin: 0;
			padding: 0;
			
			font-weight: 400;
			line-height: 1.25;
			text-align: left;
			
			color: $color-milk-black;
		}
		.post-header-info-item_title {
			font-size: 40px;
			font-weight: 500;
		}
		.post-header-info-item_date,
		.post-header-info-item_action {
			text-transform: uppercase;
		}
		.post-header-info-item_date {
			float: left;
			
			* {
				color: $color-milk-gray;
			}
		}
		.post-header-info-item_action {
			float: right;
			
			color: $color-pure-blue;
		}
	}
	
	.post-body {
		font-size: 16px;
		font-weight: 300;
		line-height: 2;
		
		* {
			margin: 2em 0 0;
		}
		h1, h2, h3, h4, h5, h6 {
			+ p, + h1, + h2, + h3, + h4, + h5, + h6 {
				margin-top: 0.25em;
			}
		}
		p:first-child {
			margin-top: 0;
		}
		ol, ul {
			margin-bottom: 0;
		}
		li {
			margin-top: 1em;
		}
		img {
			display: block;
			max-width: 100%;
			
			margin: 2em auto 0;
		}
		strong,
		b {
			font-weight: 600;
		}
		blockquote,
		.highlight {
			padding: 1.5em;
			
			border-radius: 8px;
			background: $color-pure;
		}
		blockquote {
			font-style: italic;
		}
		.highlight {
			* {
				margin: 0;
				padding: 0;
			}
			> pre > code {
				display: block;
			}
		}
		hr,
		.footnotes {
			border: none;
			border-top: 1px solid rgba($color-milk-black, 0.1);
		}
	}
	
	@media (min-width: $screen-sm) {
		.post-header {
			.post-header-info-row,
			.post-header-info-item {
				font-size: 24px;
			}
			.post-header-info-item_date {
				
			}
			.post-header-info-item_title {
				font-size: 48px;
			}
		}
		
		.post-body {
			font-size: 19.2px;
		}
	}
}

// BLOG-POST
.body_blog-post {
	color: $color-milk-black;
	background: $color-milk-white;
}
.main_blog-post {
	> .wrapper {
		max-width: $screen-sm;
	}
}
.post_blog {
	.post-header {
		.post-header-info-item_date {
			margin-bottom: 1.25em;
		}
	}
}

// PORTFOLIO-PROJECT
.body_portfolio-project {
	position: fixed;
	
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	overflow: hidden;
}
.main_portfolio-project {
	position: fixed;
	
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	z-index: 10; // 임시 처리
	
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	
	background: rgba(black, 0.5);
	
	> .wrapper {
		max-width: $screen-sm;
		
		margin-top: 0;
		margin-bottom: 0;
		
		@media (min-width: $screen-sm) {
			margin-top: $header-height;
			margin-bottom: $header-height;
		}
	}
}

.post_portfolio {
	border: 1px solid $color-milk-gray-lightest;
	background: white;
	
	.post-header {
		.post-header-cover {
			position: relative;
			
			.post-header-cover-img {
				display: block;
				
				width: 100%;
				margin: 0 auto;
			}
			
			.post-header-cover-iframe-wrapper {
				@include aspect-ratio('.post-header-cover-iframe-container', 16, 9);
			}
			.post-header-cover-iframe {
				display: block;
				
				width: 100%;
				height: 100%;
			}
			
			.post-header-cover-button_close {
				position: absolute;
				
				top: 0;
				right: 0;
				
				margin: 25px;
				
				> .post-header-cover-button_close-svg {
					display: block;
					
					width: 25px;
					height: 25px;
					
					> path {
						transform: scale(1);
					}
				}
				
				> .post-header-cover-button-svg_white {
					> path {
						stroke: rgba(white, 0.75);
					}
				}
				
				> .post-header-cover-button-svg_black {
					> path {
						stroke: rgba(black, 0.25);
					}
				}
				
				@media (min-width: $screen-sm) {
					margin: 30px;
					
					> .post-header-cover-button_close-svg {
						width: 30px;
						height: 30px;
						
						> path {
							transform: scale(1.2);
						}
					}
				}
			}
		}
		
		.post-header-info-row_detail {
			margin-top: 0.75em;
		}
	}
}