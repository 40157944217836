$header-height:		96px;
$nav-height:		64px;

$header-container-margin-horizontal:	(40 / 1024) * 100%;
$header-container-padding-vertical:		(($header-height - $nav-height) / 2);

$nav-item-padding: 16px;

@mixin header-color($color-background, $color-border, $color-text-title, $color-text) {
	background: $color-background;
	.nav-item {
		color: $color-text;
		&-active {
			border-bottom-color: $color-text;
		}
	}
	.nav-item_title {
		color: $color-text-title;
	}
	#stleamdrop {
		stroke: $color-text-title;
	}
	&.header_scrolled {
		border-bottom: 1px solid rgba($color-border, 0.1);
		box-shadow: 0 0 12px rgba($color-border, 0.1);
	}
	&.header_blur {
		background: rgba($color-background, 0.8);
		backdrop-filter: blur($header-height/4);
		-webkit-backdrop-filter: blur($header-height/4);
	}
}

// BASE
#header {
	display: block;
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	
	width: 100%;
	height: $header-height;
	margin: 0 auto;
	
	backdrop-filter: blur($header-height/4);
	-webkit-backdrop-filter: blur($header-height/4);
	
	font-family: $font-family-sans-avenir;
	
	.container {
		overflow: visible;
		
		padding: $header-container-padding-vertical 0;
	}
	
	.nav {
		display: inline-block;
		
		height: $nav-height;
	}
	.nav_left {
		float: left;
		h1 {
			height: inherit;
			
			margin: 0;
		}
	}
	.nav_right {
		float: right;
		> ul {
			margin: 0;
			padding: 0;
			> li {
				display: inline-block;
			}
		}
	}
	.nav_left,
	.nav_right {
		margin: 0 (-1 * $nav-item-padding);
	}
	
	.nav-item {
		display: inline-block;
		height: inherit;
		padding: $nav-item-padding;
		
		font-size: $font-size-h3;
		font-weight: 600;
		line-height: calc(32/24);
		text-transform: uppercase;
		
		&_active {
			border-bottom: 4px solid;
		}
	}
	
	#stleamdrop {
		margin: 4px 0;
		
		> title {
			text-transform: uppercase;
		}
	}
}

// COLORING
.header_pure {
	@include header-color($color-pure, $color-pure-black, $color-pure-blue, $color-pure-black);
}
.header_blue {
	@include header-color($color-pure-blue, rgba($color-milk-black, 0.1), white, rgba(white, 0.75));
}
.header_milk {
	@include header-color($color-milk, $color-milk-black, $color-pure-blue, $color-milk-black);
}
.header_white {
	@include header-color($color-milk-white, $color-milk-black, $color-pure-blue, $color-milk-black);
}