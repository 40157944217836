// MIXINS
@function hsv($h-hsv, $s-hsv, $b-hsv, $a: 1) {
	@if $b-hsv == 0 {
		@return hsla(0, 0, 0, $a)
	} @else {
		$l-hsl: ($b-hsv / 2) * (2 - ($s-hsv / 100));
		$s-hsl: ($b-hsv * $s-hsv) / if($l-hsl < 50, $l-hsl * 2, 200 - $l-hsl * 2);
		@return hsla($h-hsv, $s-hsl, $l-hsl, $a);
	}
}

@mixin card_portfolio-tile($card-selector, $card-margin, $card-column-number) {
	margin: -1 * ($card-margin / 2);
	
	> #{$card-selector} {
		width: calc((100% / #{$card-column-number}) - #{$card-margin});
		margin: ($card-margin / 2);
	}
}

@mixin aspect-ratio($container-selector, $width, $height) {
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		display: block;
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> #{$container-selector} {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}


// VARIABLES
//// COLORS
$color-pure-hue:			220;
$color-pure:				hsv($color-pure-hue, 5%, 100%); // = #F2F7FF
$color-pure-black:			hsv($color-pure-hue, 50%, 40%); // = #334466
$color-pure-blue:			hsv($color-pure-hue, 80%, 100%); // = #3377FF

$color-milk-white:			white;
$color-milk:				hsv(0, 0%, 98%);
$color-milk-gray-lightest:	hsv(0, 0%, 90%);
$color-milk-gray-light:		hsv(0, 0%, 75%);
$color-milk-gray:			hsv(0, 0%, 50%);
$color-milk-black:			hsv(0, 0%, 25%);

//// SCREENS
$screen-xs: 				640px;
$screen-sm: 				768px;
$screen-md: 				1024px;
$screen-lg: 				1280px;


// STYLES
* {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}